import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, map, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isSignedIn();
  }

  isSignedIn(): Observable<boolean> {
    return this.authService.tryGetUser().pipe(
        switchMap(() => this.authService.user$),
        map(user => {
          if (user === null) {
            this.router.navigate(['']);
            return false;
          }
          return true;
        })
      );
  }
}
